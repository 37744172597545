import { ReactNode, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: ReactNode;
  rootElement?: HTMLElement;
}

const Portal = ({ children, rootElement = document.body }: PortalProps) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null);
  
  useLayoutEffect(() => {
    const element = document.createElement('div');
    element.setAttribute('id', 'modal-root');
    rootElement.appendChild(element);
    document.body.classList.add('noscroll');
    setWrapperElement(element);

    return () => {
      rootElement.removeChild(element);
      document.body.classList.remove('noscroll');
    }
  }, [rootElement]);

  if (!wrapperElement) return null;

  return createPortal(children, wrapperElement);
};

export default Portal;