import React, { useEffect, useState, useContext } from "react";
import SvgComponent from "../components/SvgComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Authenticating = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(AuthContext); 

  useEffect(() => {
    // Check if token and user exist in query params
    const params = new URLSearchParams(location.search);
    const user = params.get("user");
    const token = params.get("access_token");
    const userPicture = params.get("picture");

    if (user && token) {
      // Dispatch the AUTH action to save user and token in the state
      dispatch({ type: "AUTH", user, token, userPicture });

      // navigate to home after authenticating
      navigate("/"); 
    } else {
      // Handle case where user or token is not present
      console.warn("No user or token found in query parameters.");
    //   navigate("/login"); 
    }
  }, [location.search, dispatch, navigate]);

  return (
    <div className="loginContainer">
      <div className="loginContainerContent">
        <SvgComponent w={50} h={50} />
        <h1>HackerGPT Lite</h1>
        <p>Authenticating...</p>
      </div>
    </div>
  );
};

export default Authenticating;
