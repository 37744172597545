import React, { useEffect, useRef, useState, useContext } from "react";
import IntroSection from "../components/IntroSection/IntroSection";
import Loading from "../components/Loading";
import NavContent from "../components/NavContent/NavContent";
import { getToken, getChatSession, setChatSession } from "../utils/helpers";
import { fetchChatHistory, checkAuthorization, fetchChatSessionHistory, fetchProducts, fetchCurrentSubscription, fetchBillingPortal, fetchUserMessageCount } from "../providers/main";
import { AuthContext } from "../context/AuthContext";
import ReactMarkdown from 'react-markdown';
import ChatMessageInfo from "../components/ChatMessageInfo/ChatMessageInfo";
import Loader from "../components/Loader";
import ImageViewerModal from "../components/ImageViewerModal/ImageViewerModal";
import SubscriptionModal from "../components/SubscriptionModal/SubscriptionModal";
import { FaLock } from 'react-icons/fa';
import logo from "../assets/images/Hacker-GPT-KO.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type ChatLog = {
  id: number;
  role: string;
  message: string;
  timestamp: Date;
  chat_session: string | null;
  images: any[];
}

export type SessionHistory = {
  id: number;
  start_time: string;
  end_time: string | null;
  first_message: string | null;
}

type HomeProps = {
  currentUser: string;
}


const Home = ({currentUser}: HomeProps) => {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [numberofMessagesBeforeCharge, setNumberofMessagesBeforeCharge] = useState(10);
  
  useEffect(() => {
    // Check if the URL has the query parameter 'subscribed=success'
    const urlParams = new URLSearchParams(window.location.search);
    const isSubscribedSuccess = urlParams.get('subscribed') === 'success';
  
    if (isSubscribedSuccess) {
      // If 'subscribed=success', delay the calls by 3 seconds so stripe webhook can update the database
      setTimeout(() => {
        fetchCurrentSubscription(state, dispatch, setIsSubscribed);
        fetchUserMessageCount(state, dispatch, setUserMessageCount, setNumberofMessagesBeforeCharge);
      }, 3000);
    } else {
      // Otherwise, execute the calls immediately
      fetchCurrentSubscription(state, dispatch, setIsSubscribed);
      fetchUserMessageCount(state, dispatch, setUserMessageCount, setNumberofMessagesBeforeCharge);
    }
  
  }, []);
  
  const { state, dispatch } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState<ChatLog[]>([]);
  const [sessionHistory, setSessionHistory] = useState<SessionHistory[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setResponseFromAPI] = useState(false);
  const chatLogEndRef = useRef<HTMLDivElement>(null);
  const wsRef = useRef<WebSocket | null>(null); // WebSocket reference
  const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
  const [isPaymentCardVisible, setIsPaymentCardVisible] = useState<boolean>(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState<boolean>(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string>('');

  var websocket_url = "ws://localhost:8000";

  if (process.env.NODE_ENV === "production") {
      websocket_url = "wss://"+process.env.REACT_APP_HOST;
  }
  
  const reconnectDelay = 1000; // Delay in milliseconds before reconnecting
  let isReconnecting = useRef(false);

  const connectWebSocket = async () => {
    await checkAuthorization(state, dispatch);
    
    wsRef.current = new WebSocket(`${websocket_url}/ws/chat/?token=${getToken()}&chat_session=${getChatSession() ? getChatSession() : ""}`);

    wsRef.current.onopen = () => {
      console.log("Connection established");
      isReconnecting.current = false; // Reset reconnecting flag
      setIsWebSocketOpen(true);
    };

    wsRef.current.onmessage = (event) => {
      setErr(false);
      const data = JSON.parse(event.data);
      if(data?.session_id) {
        setChatSession(data.session_id);
        return;
      }
      
      const newChatLogEntry = [{ 
        role: "assistant",
        message: data?.response?.replace(/\n{2,}/g, '\n'),
        timestamp: new Date(),
        chat_session: getChatSession(),
        images: data?.images,
        id: data?.last_message
      }];
      setChatLog((prevChatLog) => {
        // Check if the new entry ID is already present in the chat log
        const isDuplicate = prevChatLog.some(entry => entry.id === data?.last_message);
      
        // If it's a duplicate, return the current chat log without changes
        if (isDuplicate) return prevChatLog;
      
        // Otherwise, add the new entry and remove "Running..." messages
        return [
          ...prevChatLog.filter(entry => entry.message !== "Running..."),
          ...newChatLogEntry
        ];
      });
      fetchUserMessageCount(state, dispatch, setUserMessageCount, setNumberofMessagesBeforeCharge);
      setResponseFromAPI(false); // Reset response awaiting state
      if(data?.response?.includes("You have exceeded your free limit")) {
        setIsPaymentCardVisible(true);
      }
    };

    wsRef.current.onerror = (error) => {
      setErr(true);
      setIsWebSocketOpen(false);
    };

    wsRef.current.onclose = (event) => {
      console.log("Connection closed");
      isReconnecting.current = true; // Set reconnecting flag
      setIsWebSocketOpen(false);
      setTimeout(() => {
        if(getToken()) {
          console.log("Attempting to reconnect...");
          connectWebSocket();  // Attempt to reconnect
        }
      }, reconnectDelay);
    };
  };

  useEffect(() => {
    if(!!state.token) {
      fetchChatHistory(state, dispatch, setChatLog);
      fetchChatSessionHistory(state, dispatch, setSessionHistory);
    }
    
    if(!!state.token) {
      connectWebSocket(); // Initial connection
    }
    
    return () => {
      wsRef.current?.close(); // Clean up WebSocket connection on unmount
    };
  }, [refreshData]);

  // Function to manually reconnect with updated session data
  const manualReconnect = () => {
    if (wsRef.current) {
        console.log("Closing existing connection.");
        wsRef.current.close(); // Close existing connection
    }
    console.log("Establishing new connection.");
    connectWebSocket(); // Establish a new connection
    setRefreshData(!refreshData); // Refresh data
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!responseFromAPI && inputPrompt.trim() !== "") {
      let existingChatLog = chatLog;

      const newChatLogEntry = [
        { role: "user", message: inputPrompt, timestamp: new Date(), chat_session: getChatSession(), images: [], id: 0 },
        { role: "assistant", message: "Running...", timestamp: new Date(), chat_session: getChatSession(), images: [], id: 0},
      ];
      setChatLog((prevChatLog) => [...prevChatLog, ...newChatLogEntry]);

      // Hide the keyboard in mobile devices
      e.currentTarget.querySelector("input")?.blur();

      setInputPrompt(""); // Clear input after submitting
      setResponseFromAPI(true); // Indicate that a response is being awaited

      // Send the input message through WebSocket
      wsRef.current?.send(JSON.stringify({ message: inputPrompt, user_id: currentUser }));
      if(existingChatLog?.length == 0) {
        fetchChatSessionHistory(state, dispatch, setSessionHistory);
      }
    }
  };

  // Scroll to the bottom of the chat log when new messages are added
  useEffect(() => {
    if (chatLogEndRef.current) {
      chatLogEndRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }

    const codeBlocks = document.querySelectorAll('code');
    codeBlocks.forEach((codeBlock) => {
      codeBlock.addEventListener('click', handleCodeCopy);
    });
  }, [chatLog]);

  const handleOpenImageModal = (imageSrc: string): void => {
    setSelectedImageSrc(imageSrc);
    setIsImageModalVisible(true);
  }

  const handleCloseImageModal = (): void => {
    setSelectedImageSrc('');
    setIsImageModalVisible(false);
  }

  const isPaymentRequired = () => {
    if (currentUser?.split("@")[1] == "whitehacklabs.com") {
      return false;
    }
    return (userMessageCount >= numberofMessagesBeforeCharge && !isSubscribed);
  };

  // Function to handle the copy action
  const handleCodeCopy = (event: any) => {
    const codeContent = event.target.textContent;
  
    // Use the Clipboard API to copy text
    navigator.clipboard.writeText(codeContent)
      .then(() => {
        // Show the toast notification
        toast.info('Link copied to clipboard!');
      });
  };

  const preprocessMessage = (message: string) => {
    // Regex for detecting the [link](link) pattern
    const onionLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]*\.onion[^\)]*)\)/g;
    // Regex for detecting plain .onion links
    const plainOnionLinkRegex = /(https?:\/\/[^\s]*\.onion[^\s]*)/g;

    // Remove the **URL:** pattern
    if (message?.includes("dark")) {
        message = message?.replace(/\*\*.*?URL:\*\*/g, "");
    }

    // First, replace the [link](link) pattern with code block
    message = message?.replace(onionLinkRegex, (match, text, url) => {
        return `\`\`\` ${url} \`\`\``;
    });

    if(message?.includes("``` ") && message?.includes("dark")) {
      return message;
    }

    // If not already been replaced, replace plain .onion URLs with code blocks
    message = message?.replace(plainOnionLinkRegex, (match, url) => {
        // Check if the URL is already wrapped in a code block
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return `\`\`\` ${url} \`\`\``;
        }
        return url;
    });

    return message;
  }


  return (
    <div className="App">
      <ToastContainer 
        theme="dark"
        position="top-right"
        autoClose={3000}
      />
      <header className="menu-bg">
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <img style={{width: "40%", marginLeft: "auto", marginRight: "auto", marginBottom: "5px"}} src={logo} alt="HackerGpt Lite"  />
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={chatLog}
              setChatLog={setChatLog}
              sessionHistory={sessionHistory}
              manualReconnect={manualReconnect}
              setIsPaymentCardVisible={setIsPaymentCardVisible}
              isSubscribed={isSubscribed}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent
          chatLog={chatLog}
          setChatLog={setChatLog}
          sessionHistory={sessionHistory}
          manualReconnect={manualReconnect}
          setIsPaymentCardVisible={setIsPaymentCardVisible}
          isSubscribed={isSubscribed}
        />
      </aside>

      {!isWebSocketOpen ? <Loader /> : null}
      <section className="chatBox">


      {(isPaymentCardVisible || (userMessageCount >= numberofMessagesBeforeCharge && !isSubscribed)) &&
        <SubscriptionModal isOpen={isPaymentCardVisible} onClose={() => {setIsPaymentCardVisible(false)}} />
      }
      



        {chatLog.length > 0 ? (
          <div className="chatLogWrapper">
            {chatLog.map((chat, idx) => (
              <div className="chatLog" key={idx} id={`chat-${idx}`}>
                
                {/* User message */}
                {chat?.role === "user" && 
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                      <ChatMessageInfo
                        username={`/${currentUser}`}
                        userColor={'#3ebac6'}
                        timestamp={chat.timestamp}
                        userPicture={state.userPicture}
                      />
                      <div id="chatPrompt" className="chatPrompt">{chat.message}<span>/</span></div>
                    </div>
                  </div>
                }
                
                {/* Bot response */}
                {chat?.role === "assistant" && 
                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <ChatMessageInfo username={'/HackerGPT'} userColor={'#c03fc1'} timestamp={chat.timestamp} />
                      {chat.message === "Running..." ? (
                        <Loading />
                      ) : (
                        <div id="botMessage" className="botMessage">
                          <ReactMarkdown>
                          {preprocessMessage(chat?.message)}
                            </ReactMarkdown>
                          {chat.images.length > 0 && (
                            <div className="imageGrid">
                              {chat.images.map((image, idx) => (
                                  <img
                                    className="screenshot"
                                    key={idx}
                                    src={image.image_url}
                                    alt={chat.message?.slice(0, 400)+"..."}
                                    onClick={() => handleOpenImageModal(image.image_url)}
                                  />
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
                
              </div>
            ))}
            <div ref={chatLogEndRef} />{" "}
            {/* Invisible element to scroll into view */}
          </div>
        ) : (
          <IntroSection />
        )}

        <ImageViewerModal
          imageSrc={selectedImageSrc}
          isOpen={isImageModalVisible}
          onClose={handleCloseImageModal}
        />

        <form onSubmit={handleSubmit}>
        
          <div className="inputPromptWrapper" style={{ position: "relative" }} onClick={() => (isPaymentRequired()) ? setIsPaymentCardVisible(true) : {}}>
          <span>&#8594;</span>
            <input
              name="inputPrompt"
              id=""
              className="inputPrompttTextarea"
              type="text"
              value={inputPrompt}
              placeholder="Message HackerGPT"
              onChange={(e) => setInputPrompt(e.target.value)}
              autoFocus
              disabled={isPaymentRequired()}
              style={{filter: (isPaymentRequired()) ? "blur(2px)":""}}
            ></input>
            { isPaymentRequired() &&
              <>
              <b 
              className="inputLockArea"
                style={{
                  position: 'absolute',
                  right: '50%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#888',
                  zIndex: 10,
                  cursor: 'pointer'
                }}
              >
                Subscribe to continue
                <FaLock style={{ marginLeft: "5px" }} />
              </b>
              
              </>
            }
              
            <button aria-label="form submit" type="submit" style={{filter: (isPaymentRequired()) ? "blur(2px)":""}}>
              <svg
                fill="#ADACBF"
                width={15}
                height={20}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#212023"
                strokeWidth={0}
              >
                <title>{"submit form"}</title>
                <path
                  d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                  stroke="none"
                />
              </svg>
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Home;