import { AuthContextActions, AuthContextState } from "./AuthContext";

function AuthReducer(state: AuthContextState, action: AuthContextActions) {
  switch (action.type) {
    case "AUTH": {
      return {
        ...state,
        currentUser: action.user,
        token: action.token,
        userPicture: action.userPicture,
      };
    }
    case "LOGOUT": {
      return {
        currentUser: "",
        token: "",
        userPicture: "",
      };
    }
    default: {
      return state;
    }
  }
}

export default AuthReducer;
