import React from "react";
import NavLinksContainer from "../NavLinksContainer";
import NavPrompt from "../NavPrompt";
import NewChat from "../NewChat";
import { ChatLog, SessionHistory } from "../../pages/Home";
import logo from "../../assets/images/Hacker-GPT-KO.png";

import styles from "./NavContent.module.css";

type NavContentProps = {
  chatLog: ChatLog[];
  setChatLog: any;
  sessionHistory: SessionHistory[];
  manualReconnect: any;
  setIsPaymentCardVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isSubscribed: boolean;
}

const NavContent = ({ chatLog, setChatLog, sessionHistory, manualReconnect, setIsPaymentCardVisible, isSubscribed }: NavContentProps) => {
  return (
    <>
      <img src={logo} alt="HackerGpt Lite" className={styles.logo} />
      <NewChat setChatLog={setChatLog} manualReconnect={manualReconnect} />
      <div className={styles.navPromptWrapper}>
        {sessionHistory.map(
          (session) =>
            <NavPrompt id={session.id} chatPrompt={session.first_message ? session.first_message : "New session"} key={session.id} manualReconnect={manualReconnect} />
        )}
      </div>
      <NavLinksContainer isSubscribed={isSubscribed} setIsPaymentCardVisible={setIsPaymentCardVisible} chatLog={chatLog} setChatLog={setChatLog} manualReconnect={manualReconnect} />
    </>
  );
};

export default NavContent;
