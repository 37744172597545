import { PropsWithChildren } from "react";
import styles from './Avatar.module.css';

const Avatar = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <div className={styles.avatar}>{children}</div>
    </div>
  );
};

export default Avatar;
