import BotResponse from "../BotResponse";
import styles from "./IntroSection.module.css";

const IntroSection = () => {
  return (
    <div id={styles.introsection}>
      <h1>
        Welcome to HackerGPT Lite
        <BotResponse response=" - AI Powered OSINT and Discovery Tool" chatLogRef={undefined} />
      </h1>
      <h2>
        An autonomous tool designed to perform OSINT and discovery tasks for penetration testers using a combination of generative AI methodologies.**<span>/</span>
      </h2>
      <p>
        Start your security assessment by giving it a "check hackergpt.app" prompt.**<span>/</span>
      </p>

      <div className={styles.separator}></div>

      <div className={styles.chatContainer}>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### Check domain:/</legend>
          <p className={styles.emptyChatDescription}>check hackergpt.app **/</p>
        </fieldset>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### Subdomain Enumeration:/</legend>
          <p className={styles.emptyChatDescription}>Find all available subdomains for example.com **/</p>
        </fieldset>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### Domain Registration Analysis:/</legend>
          <p className={styles.emptyChatDescription}>Get domain registration details for hackergpt.app **/</p>
        </fieldset>
      </div>
      <div className={styles.chatContainer}>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### IP / Domain Geolocation:/</legend>
          <p className={styles.emptyChatDescription}>Find geolocation for hackergpt.app **/</p>
        </fieldset>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### Dark Web Intelligence:/</legend>
          <p className={styles.emptyChatDescription}>Search the dark web for any mentions of HackerGPT **/</p>
        </fieldset>
        <fieldset className={styles.emptyChatTile}>
          <legend className={styles.emptyChatTitle}> ### SSL Security Assessment:/</legend>
          <p className={styles.emptyChatDescription}>Performs an SSL vulnerability scan on hackergpt.app**/</p>
        </fieldset>
      </div>

    </div>
  );
};

export default IntroSection;
