export const getToken = () => {
    return localStorage.getItem("token", "");
}

export const removeToken = () => {
    return localStorage.removeItem("token");
}

export const setToken = (token) => {
    return localStorage.setItem("token", token);
}

export const getChatSession = () => {
    return localStorage.getItem("chat_session", "");
}

export const setChatSession = (chat_session) => {
    return localStorage.setItem("chat_session", chat_session);
}