import { ReactComponent as Logo } from "../../assets/images/whl_logo.svg";
import logo from "../../assets/images/Hacker-GPT-KO.png";

import styles from "./Login.module.css";

const Login = () => {
  let BACKEND_URL = "http://localhost:8000";

  if (process.env.NODE_ENV === "production") {
    BACKEND_URL = "https://"+process.env.REACT_APP_HOST;
  }

  const handleSignUpWithGoogle = async () => {
    try {
      // redirect to Google sign-in page
      window.location.href = `${BACKEND_URL}/chat/google-oauth2/login/redirect/`;
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className={styles.wrapper}>
      <video muted loop autoPlay playsInline className={styles.bgVideo}>
        <source src="/hgl-background.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className={styles.loginContainer}>
        <div className={styles.loginContainerContent}>
          <h1>Welcome to</h1>
          <img src={logo} alt="HackerGpt Lite" className={styles.logo} />

          <div className={styles.loginButtonWrapper}>
            <button className={styles.loginWithGoogleBtn} id="signupWithGoogle" onClick={handleSignUpWithGoogle}>
              Continue with Google
            </button>
          </div>
          
        </div>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default Login;
