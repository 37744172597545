import { createContext, PropsWithChildren, useEffect, useReducer } from "react";
import reducer from "./AuthReducer";

export type AuthContextState = {
  currentUser: string;
  token: string;
  userPicture: string;
}

export type AuthContextActions = {
  type: 'AUTH' | 'LOGOUT';
  user: string;
  token: string;
  userPicture: string;
}

const initialState: AuthContextState = {
  currentUser: localStorage.getItem("user") || "",
  token: localStorage.getItem("token") || "",
  userPicture: localStorage.getItem("userPicture") || "",
};

export const AuthContext = createContext<{state: AuthContextState, dispatch: React.Dispatch<AuthContextActions>}>({state: initialState, dispatch: () => {}});

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem("user", state.currentUser);
    localStorage.setItem("token", state.token);
    localStorage.setItem("userPicture", state.userPicture);
  }, [state.currentUser, state.token, state.userPicture]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
