import "./normal.css";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login/Login";
import Authenticating from "./pages/Authenticating";
import { Navigate, Route, Routes } from "react-router-dom";
import { PropsWithChildren, useContext } from "react";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { state } = useContext(AuthContext);

  // const RequireAuth = ({ children }: PropsWithChildren) => {
  //   return state.currentUser ? children : <Navigate to="auth/login" />;
  // };
  const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return state.currentUser && state.token ? <>{children}</> : <Navigate to="auth/login" />;
  };

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <RequireAuth>
            <Home currentUser={state.currentUser} />
          </RequireAuth>
        }
      />
      <Route path="auth/google-oauth2/login/callback" element={<Authenticating />} />
      <Route path="auth/login" element={<Login />} />
    </Routes>
  );
}

export default App;
