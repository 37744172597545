import React, {useContext} from "react";
import { setChatSession } from "../utils/helpers";
import { createChatSession } from "../providers/main";
import { AuthContext } from "../context/AuthContext";

const NewChat = ({ setChatLog, manualReconnect }) => {
  const { state, dispatch } = useContext(AuthContext);
  return (
    <div
      className="sideMenuButton"
      onClick={() => {
        setChatLog([]);
        createChatSession(state, dispatch, manualReconnect)
      }}
    >
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
