import { useState, useContext } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { createCheckoutSession, fetchProducts } from "../../providers/main";
import { AuthContext } from "../../context/AuthContext";
import Modal from "../Modal/Modal";
import logo from "../../assets/images/Hacker-GPT-KO.png";
import { ReactComponent as CloseIcon } from "../../assets/images/close_icon.svg";

import styles from "./SubscriptionModal.module.css";

type SubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SubscriptionModal = ({ isOpen, onClose }: SubscriptionModalProps) => {
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubscribeNow = (): void => {
    setLoading(true);
    const fetchAndCreateSession = async () => {
      try {
        const res = await fetchProducts(state, dispatch);
        if (res?.products?.length > 0) {
          const session = await createCheckoutSession(state, dispatch, res.products[0]?.id);
          window.location.href = session.url;
        }
      } catch (error) {
        console.error("Error during fetch or checkout session:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndCreateSession();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeader}>
          <button className={styles.closeBtn} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.cardContent}>
          <img className={styles.logo} src={logo} alt="HackerGpt Lite" />
          <h3 className={styles.cardTitle}>Monthly Subscription</h3>
          <p className={styles.cardPrice}>$19</p>
          <p className={styles.cardDescription}>Get unlimited access to HackerGPT Lite features, including Dark Web Search, Service Discovery Scans, IP Location and WHOIS lookups and more...</p>
          <button className={styles.cardButton} onClick={handleSubscribeNow}>
            {loading ?
              <PulseLoader
                color={"#fff"}
                style={{ margin: "45px" }}
                loading={true}
                size={10}
                aria-label="Connecting"
                data-testid="loader"
              />
              :
              "Subscribe Now"
            }
          </button>
        </div>
      </div>
    </Modal>
  )
};

export default SubscriptionModal;