import Modal from "../Modal/Modal";
import { ReactComponent as DownloadIcon } from "../../assets/images/download_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close_icon.svg";

import styles from "./ImageViewerModal.module.css";

type ImageViewerModalProps = {
  imageSrc: string;
  isOpen: boolean;
  onClose: () => void;
};

const ImageViewerModal = ({ imageSrc, isOpen, onClose }: ImageViewerModalProps) => {
  const onDownload = (): void => {
    fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', imageSrc.split('/').reverse()[0]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        console.log('Failed to download', imageSrc);
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeader}>
          <button className={styles.btn} onClick={onDownload}>
            <DownloadIcon />
          </button>
          <button className={styles.btn} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.content}>
          <img className={styles.image} src={imageSrc} alt="" />
        </div>
      </div>
    </Modal>
  )
};

export default ImageViewerModal;