import axios from "axios";
import { setChatSession, getChatSession } from "../utils/helpers";

var BACKEND_URL = "http://localhost:8000";

if (process.env.NODE_ENV === "production") {
    BACKEND_URL = "https://"+process.env.REACT_APP_HOST;
}


export const checkAuthorization = async (state, dispatch) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/chat/authorization/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });  
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}


export const fetchChatHistory = async (state, dispatch, setChatLog, setErr = () => {}) => {
    try {
        const sessionId = getChatSession();
        if (sessionId) {
        const response = await axios.get(`${BACKEND_URL}/chat/chat-history/${sessionId}/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setChatLog(response.data.messages.map((message) => ({
            ...message,
            message: message.message.replace(/\n{2,}/g, '\n') // Replace double line breaks with single
        })));
        }
    } catch (error) {
        setErr(true);
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}

export const deleteChatSession = async (state, dispatch, manualReconnect) => {
    try {
        const sessionId = getChatSession();
        if (sessionId) {
          const response = await axios.delete(`${BACKEND_URL}/chat/chat-history/${sessionId}/`, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          });
          manualReconnect();
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
};


export const createChatSession = async (state, dispatch, manualReconnect) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/chat/session/create/`, {}, {
            headers: {
                Authorization: `Bearer ${state.token}`,
            },
        });
        setChatSession(response.data.id);
        manualReconnect();
        return response.data; 
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
};


export const fetchChatSessionHistory = async (state, dispatch, setSessionHistory, setErr = () => {}) => {
    try {
        const sessionId = getChatSession();
        if (sessionId) {
        const response = await axios.get(`${BACKEND_URL}/chat/sessions-history/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setSessionHistory(response.data);
        }
    } catch (error) {
        setErr(true);
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}



export const fetchProducts = async (state, dispatch) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/payment/stripe_products/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}



export const createCheckoutSession = async (state, dispatch, product_id) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/payment/stripe_checkout/`, {
            product_id: product_id,
        }, {
            headers: {
                Authorization: `Bearer ${state.token}`,
            },
        });
        return response.data; 
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
};


export const fetchCurrentSubscription = async (state, dispatch, setIsSubscribed) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/payment/stripe_current_subscription/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setIsSubscribed(response.data.status);
        return response.data;
    } catch (error) {
        setIsSubscribed(false);
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}


export const fetchUserMessageCount = async (state, dispatch, setUserMessageCount, setNumberofMessagesBeforeCharge) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/chat/message/count/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setUserMessageCount(response?.data?.message_count);
        setNumberofMessagesBeforeCharge(response?.data?.message_limit);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}

export const fetchBillingPortal = async (state, dispatch) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/payment/stripe_billing/`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        window.location.href = response.data.url;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: "LOGOUT" });
        }
    }
}